<template>
  <form @submit="handleSubmit" class="my-8 p-4">
    <h2>Clinical Questions</h2>
    <p>Please answer the following questions to the best of your ability.</p>
    <loader v-if="isLoading" size="small" class="mx-auto" />
    <div class="questions-grid">
      <div v-for="question in questions" :key="question.id">
        <label>{{ question.displayText }}</label>
        <div v-if="question.id">
          <div v-if="question.cytQuestionType === 'Text'">
            <TextInput
              v-model="answerMap[question.id]"
              :required="question.isResponseRequired"
              type="text"
            />
          </div>
          <div v-else-if="question.cytQuestionType === 'Date'">
            <DatePicker v-model="answerMap[question.id]" :required="question.isResponseRequired" />
          </div>
          <div v-else-if="question.cytQuestionType === 'textarea'">
            <textarea
              v-model="answerMap[question.id]"
              :required="question.isResponseRequired"
            ></textarea>
          </div>
          <div v-else-if="question.cytQuestionType === 'List'">
            <SelectInput
              :items="question.options"
              v-model="answerMap[question.id]"
              :required="question.isResponseRequired"
            >
            </SelectInput>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <button type="submit" class="btn btn-primary ml-auto">Save Responses</button>
    </div>
  </form>
</template>

<script>
import { dateRangeFilter } from "@/modules/helpers";
import { CasesApi } from "@/services";
import cytologyService, { CytologyEntities } from "@/services/cytology";
import { mapState } from "vuex";
import DatePicker from "../common/DatePicker.vue";
import TextInput from "../common/TextInput.vue";
import SelectInput from "../common/SelectInput.vue";
import { CytQuestionTypeEnum } from "@/modules/enums";
import Loader from "../common/Loader.vue";

export default {
  components: {
    DatePicker,
    TextInput,
    SelectInput,
    Loader
  },
  props: {
    caseId: {
      required: true
    }
  },
  data() {
    return {
      answerMap: {},
      isLoading: false,
      questionResponses: [],
      molecTests: [],
      cytologyQuestionsStore: cytologyService.createSearch(CytologyEntities.CytQuestions),
      labQuestions: {}
    };
  },
  created() {
    this.loadLabQuestions();
  },
  methods: {
    async loadAnswerMap() {
      try {
        this.isLoading = true;
        this.questionResponses = await CasesApi.getCytologyResponses(this.caseId);

        if (this.questionResponses.length > 0) {
          this.answerMap = this.questionResponses.reduce((acc, curr) => {
            acc[curr.cytQuestionId] = curr.response;

            return acc;
          }, {});
        }
      } finally {
        this.isLoading = false;
      }
    },
    async loadLabQuestions() {
      try {
        this.isLoading = true;
        const labQuestions = await this.cytologyQuestionsStore.load({
          filter: dateRangeFilter(),
          sort: [
            {
              selector: "seqNum",
              desc: false
            }
          ]
        });

        if (!labQuestions?.length) return;
        for (const question of labQuestions) {
          if (question.cytQuestionType === "List") {
            const questionDetails = await cytologyService.getSingleEntity(
              CytologyEntities.CytQuestions,
              question.id
            );
            question.options = questionDetails.options;
          }
        }
        this.labQuestions = labQuestions.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        await this.loadAnswerMap();
        //! TODO: add error handling
      } finally {
        this.isLoading = false;
      }
    },
    setResponse(questionId, response) {
      this.answerMap[questionId] = response;
    },
    getQuestionResponse(questionId) {
      const response = this.questionResponses.find(q => q.questionId === questionId);
      return response ? response.response : "";
    },
    async handleSubmit(event) {
      event.preventDefault();
      try {
        this.isLoading = true;
        const items = [];
        for (const questionId in this.answerMap) {
          const question = this.labQuestions[questionId];
          const responseId = this.responsesMap[questionId];
          if (!question) continue;
          if (question.cytQuestionType === CytQuestionTypeEnum.List) {
            items.push({
              cytQuestionId: parseInt(questionId),
              cytQuestionOptionId: this.answerMap[questionId],
              response: "",
              id: responseId || null
            });
          } else {
            items.push({
              cytQuestionId: parseInt(questionId),
              response: this.answerMap[questionId],
              id: responseId || null
            });
          }
        }
        const putRecords = items.filter(e => e.id);
        const postRecords = items.filter(e => !e.id);
        if (putRecords.length) {
          await CasesApi.updateCytologyResponses(this.caseId, {
            id: this.caseId,
            items: putRecords
          });
        }
        if (postRecords.length) {
          await CasesApi.insertCytologyResponses(this.caseId, {
            id: this.caseId,
            items: postRecords
          });
        }
        await this.loadAnswerMap();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.caseDetails.specimens
    }),
    responsesMap() {
      return this.questionResponses.reduce((acc, curr) => {
        acc[curr.cytQuestionId] = curr.id;
        return acc;
      }, {});
    },
    questions() {
      return Object.values(this.labQuestions);
    }
  }
};
</script>

<style lang="scss" scoped>
.questions-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  .loader {
    align-self: center;
  }
}
</style>
